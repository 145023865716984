import React from 'react'
import {connect} from 'react-redux'
import {HelpIcon, I18nMessage} from 'assecobs-faktor-web-common'
import _ from 'lodash'
import {Config} from 'assecobs-faktor-web-common/index'

class ExtensionsInfoComponent extends React.Component {

  render() {

    const {fileExtensions, fileExtensionsFetching, isMultiple} = this.props

    let fileExtensionsGroups = []

    fileExtensions.forEach(fileExtension => {

      if (_.isEmpty(fileExtensionsGroups)) {
        fileExtensionsGroups.push({
          formats: fileExtension.get('fileExtensionName'),
          size: fileExtension.get('attachmentMaxSize'),
        })
      } else {

        let added = false
        fileExtensionsGroups.forEach(fileExtensionsGroup => {
          if (_.isEqual(fileExtensionsGroup.size, fileExtension.get('attachmentMaxSize'))) {
            fileExtensionsGroup.formats += ', ' + fileExtension.get('fileExtensionName')
            added = true
          }
        })

        if (!added) {
          fileExtensionsGroups.push({
            formats: fileExtension.get('fileExtensionName'),
            size: fileExtension.get('attachmentMaxSize'),
          })
        }
      }
    })

    const PopoverComponent = !fileExtensionsFetching ? <div>
      <I18nMessage id="fw.attachment.adding.helpTooltip.header" />:
      <ul className="fw-attachment-tooltip-list">
        {fileExtensionsGroups.map((fileExtensionsGroup, index) => {
          return <li key={index}>
            {fileExtensionsGroup.formats}: <I18nMessage
            id="fw.attachment.adding.helpTooltip.maxSize" /> {fileExtensionsGroup.size}<I18nMessage
            id="fw.attachment.adding.helpTooltip.maxSize.mb" />
          </li>
        })}
      </ul>
      {isMultiple && <I18nMessage id="fw.attachment.adding.maxBunchFilesSize" values={{
        maxFileSize: Config.getMaxRequestSize(),
      }} />}
    </div> : <div />

    return (
      <HelpIcon overlayPlacement="right" PopoverContent={PopoverComponent}
                iconClassName="fw-attachment-extensions-help-icon"
                tooltipClassName="fw-attachment-tooltip"
                iconShow={true} />
    )
  }
}

export default connect(({fileExtensionReducer}) => ({
  fileExtensionsFetching: fileExtensionReducer.isFetching,
  fileExtensions: fileExtensionReducer.fileExtensions,
}))(ExtensionsInfoComponent)