import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {Collapse, Overlay, Popover} from 'react-bootstrap'
import MediaQuery from 'react-responsive'
import {equals, isNil} from 'ramda'

class HelpIconComponent extends React.Component {

  render() {
    const {iconClassName, onIconClick} = this.props

    return <div className={classnames('fw-help-icon', iconClassName)}>
      <i className="abs-icon abs_live_help" onClick={onIconClick} ref={c => this._icon = c} />
      {this.props.children}
    </div>
  }
}

class OverlayHelpIconComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {showTooltip: false, overlayHelpIcon: null}
  }

  onIconClick = () => {
    this.setState((prevState) => ({showTooltip: !prevState.showTooltip}))
    if (this.props.onIconClick) {
      this.props.onIconClick()
    }

  }

  closeTooltip = () => {
    this.setState({showTooltip: false})

    if (this.props.overlayOnHide) {
      this.props.overlayOnHide()
    }

  }

  render() {
    const {overlayPlacement, PopoverContent, iconClassName, tooltipClassName, show} = this.props

    return (
      <HelpIconComponent ref={c => this.state.overlayHelpIcon = c} iconClassName={iconClassName}
                         onIconClick={this.onIconClick}>
        <Overlay placement={overlayPlacement}
                 show={this.state.showTooltip || show}
                 onHide={this.closeTooltip}
                 rootClose
                 target={!isNil(this.state.overlayHelpIcon) ? this.state.overlayHelpIcon._icon : null}>
          <Popover id="tooltip" className={classnames('fw-popover-tooltip', tooltipClassName)}>
            {PopoverContent}
          </Popover>
        </Overlay>
      </HelpIconComponent>
    )
  }

  static get propTypes() {
    return {
      overlayPlacement: PropTypes.string.isRequired,
      overlayOnHide: PropTypes.func,
      onIconClick: PropTypes.func,
      PopoverContent: PropTypes.element,
      iconClassName: PropTypes.string,
      tooltipClassName: PropTypes.string,
    }
  }

  static get defaultProps() {
    return {
      overlayPlacement: 'bottom',
      iconClassName: '',
    }
  }
}

const collapseHelpIcon = Symbol()

class CollapseHelpIconComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {show: false}
    this[collapseHelpIcon] = null
  }

  componentDidMount() {
    window.addEventListener('scroll', this.windowUpdate)
    window.addEventListener('resize', this.windowUpdate)
    document.addEventListener('click', this.closeCollapse)
    document.getElementById('tooltip').addEventListener('click', this.preventClosing)
  }

  componentDidUpdate() {
    document.getElementsByClassName('arrow')[0].style.left =
      this[collapseHelpIcon]._icon.getBoundingClientRect().left + this[collapseHelpIcon]._icon.offsetWidth / 2 + 'px'

    document.getElementsByClassName('arrow')[0].style.top =
      document.getElementById('tooltip').getBoundingClientRect().top - document.getElementsByClassName('arrow')[0].offsetHeight + 1 + 'px'
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.windowUpdate)
    window.removeEventListener('resize', this.windowUpdate)
    document.removeEventListener('click', this.closeCollapse)
    document.getElementById('tooltip').removeEventListener('click', this.preventClosing)
  }

  windowUpdate = () => {
    this.forceUpdate()
  }

  closeCollapse = (event) => {
    if (!equals(event.target, this[collapseHelpIcon]._icon) && this.state.show) {
      this.setState({show: false})
    }

  }

  preventClosing = (event) => {
    event.stopImmediatePropagation()
  }

  onIconClick = () => {
    this.setState((prevState) => ({show: !prevState.show}))
    if (this.props.onIconClick) {
      this.props.onIconClick()
    }

  }

  render() {
    const {PopoverContent, iconClassName, tooltipClassName, popoverProps, show} = this.props

    return [
      <HelpIconComponent key={1} ref={c => this[collapseHelpIcon] = c} iconClassName={iconClassName}
                         onIconClick={this.onIconClick} />,
      <Collapse in={this.state.show || show} key={2}>
        <Popover id="tooltip" className={classnames('fw-popover-tooltip',
          {
            'fw-popover-tooltip--hidden': !this.state.show,
          }, tooltipClassName)}
                 {...popoverProps}>
          {PopoverContent}
        </Popover>
      </Collapse>,
    ]
  }

  static get propTypes() {
    return {
      onIconClick: PropTypes.func,
      PopoverContent: PropTypes.element,
      iconClassName: PropTypes.string,
      tooltipClassName: PropTypes.string,
      popoverProps: PropTypes.object,
    }
  }

  static get defaultProps() {
    return {
      iconClassName: '',
      tooltipClassName: '',
      popoverProps: {
        placement: 'bottom',
        arrowOffsetLeft: '200',
      },
    }
  }

}

export default class HelpIcon extends React.Component {
  render() {
    const {iconShow} = this.props

    if (iconShow === false || isNil(iconShow)) {
      return null
    }

    return [
      <MediaQuery maxWidth={767} key="collapseTooltip">
        <CollapseHelpIconComponent {...this.props} />
      </MediaQuery>,

      <MediaQuery minWidth={768} key="overlayTooltip">
        <OverlayHelpIconComponent {...this.props} />
      </MediaQuery>,
    ]
  }
}