import React from 'react'
import PropTypes from 'prop-types'
import {FormattedNumber} from 'react-intl'
import {isNil} from 'ramda'
import {HelpIcon} from 'assecobs-faktor-web-common'

export default class Money extends React.Component {

  componentDidMount() {
    if (isNil(this.props.amount)) {
      if (this.props.elementIsNull) {
        this.props.elementIsNull()
      }
    }
  }

  renderContent = (formattedNumber) => {
    const integer = formattedNumber.substring(0, formattedNumber.length - 3)
    const fraction = formattedNumber.substring(formattedNumber.length - 3, formattedNumber.length)

    return [
      <span key={0} className="formatted-money-integer">{integer}</span>,
      <span key={1} className="formatted-money-fraction">{fraction}</span>,
    ]
  }

  render() {
    let {
      amount,
      currency,
      minimumFractionDigits,
      maximumFractionDigits,
      colorize,
      iconClassName,
      iconMessage,
      iconShow,
    } = this.props

    if (isNil(amount)) {
      return null
    }

    minimumFractionDigits = minimumFractionDigits === undefined ? 2 : minimumFractionDigits
    maximumFractionDigits = maximumFractionDigits === undefined ? 2 : maximumFractionDigits

    if (minimumFractionDigits > maximumFractionDigits) {
      minimumFractionDigits = maximumFractionDigits
    }

    return (
      <span className={'formatted-money' + (colorize ? (amount > 0 ? ' success' : ' danger') : '')}>
        <FormattedNumber value={amount}
                         minimumFractionDigits={minimumFractionDigits}
                         maximumFractionDigits={maximumFractionDigits}>
                    {this.renderContent}
        </FormattedNumber>
        <span className="formatted-money-currency">{currency}</span>
        <HelpIcon iconClassName={iconClassName}
                  PopoverContent={iconMessage}
                  iconShow={iconShow} />
      </span>
    )
  }
}

Money.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string,
  minimumFractionDigits: PropTypes.number,
  maximumFractionDigits: PropTypes.number,
  colorize: PropTypes.bool,
}

Money.defaultProps = {
  colorize: false,
}
