import _ from 'lodash'
import {getContext} from 'assecobs-faktor-web-common/index'
import restService from 'assecobs-react-utils/restService'
import fwUrlUtils from '../../utils/fwUrlUtils'

const STORAGE_KEY = 'instance.config'
const FETCH_INSTANCE_CONFIG_METADATA_URL = fwUrlUtils.getApiUrl('/config/user/componentsMeta')
const FETCH_SSO_MODE_URL = fwUrlUtils.getApiUrl('/config/user/ssoMode')

const emptyMetaData = {module: null, window: null, component: null, element: null, data: {}}

const initInstanceConfigMetaData = async () => {
  const contextClientId = await getClientId()

  if (_.isNil(contextClientId)) {
    return Promise.resolve([])
  }

  const storage = getInstanceConfigMetaData()

  if (!_.isEmpty(storage) && contextClientId === storage.clientId) {
    return Promise.resolve(storage.metaData)
  }

  await fetchAndSaveMetaData(contextClientId)

  return Promise.resolve(await fetchInstanceConfigMetaData())
}

const initSSOMode = async () => {
  const contextClientId = await getClientId()

  if (_.isNil(contextClientId)) {
    return Promise.resolve(false)
  }

  const storage = getInstanceConfigMetaData()

  if (!_.isEmpty(storage) && contextClientId === storage.clientId) {
    return Promise.resolve(storage.ssoMode)
  }

  await fetchAndSaveMetaData(contextClientId)

  return Promise.resolve(await fetchSSOModeData())
}

const fetchAndSaveMetaData = async (clientId) => {
  const fetchedInstanceConfigMetaData = await fetchInstanceConfigMetaData()
  const fetchedSSOModeData = await fetchSSOModeData()
  save(clientId, fetchedInstanceConfigMetaData, fetchedSSOModeData)
}

const save = (clientId, metaData, ssoMode) => {
  const _metaData = metaData.map(rec => {
    return {...emptyMetaData, ...rec}
  })

  sessionStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({clientId, metaData: _metaData, ssoMode}),
  )

  return metaData
}

const getInstanceConfigMetaData = () => {
  const storageItem = sessionStorage.getItem(STORAGE_KEY)
  return storageItem ? JSON.parse(storageItem) : storageItem
}

const fetchInstanceConfigMetaData = async () => {
  return restService.getJSON(FETCH_INSTANCE_CONFIG_METADATA_URL).then(result => {
    return result
  })
}

const fetchSSOModeData = async () => {
  return restService.getJSON(FETCH_SSO_MODE_URL).then(result => {
    return result
  })
}

const getClientId = async () => {
  const userContext = await getContext()

  if (_.isEmpty(userContext)) {
    return Promise.resolve(void 0)
  }

  return Promise.resolve(userContext.get('clientId'))
}

export {
  initInstanceConfigMetaData,
  initSSOMode,
  getInstanceConfigMetaData,
}